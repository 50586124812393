import React from 'react';
import '../styles/style.css';

function NotFound() {
  return (
    <div className="Home">
        Page Not Found
    </div>
  );
}

export default NotFound;